import React, { useState } from "react"
import { css } from "@emotion/react"
import PropTypes from "prop-types"
import resourceListStyle from "../styles/resourceList.scss"
import ModalItem from '../components/Modal';
import { useIntl } from "gatsby-plugin-intl"
import { openExternalLink } from "../utils";

const ResourceList = ({ data }) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="page-main-container"
      css={[css(resourceListStyle)]}
    >
      <div className="page-main">
        <div className="services">
          {data.map((resource, i) => (
            <div
              className={'services-content-box ' + (!resource.resourceSectionTitle ? 'content-only' : undefined)}
              key={resource.resourceSectionTitle}
            >
              {resource.resourceSectionTitle && <h2 key={i}>{resource.resourceSectionTitle}</h2>}
              <div key={resource} className="services-content-box-listings">
                {resource.resourceList &&
                  resource.resourceList.map((resourceSection, index) => (
                    <div className="service-box-container" key={index}>
                      <div className="resource-list">
                        <div>
                          <div className="resource-list-header">
                            {resourceSection.icon && (
                              <img
                                src={resourceSection.icon.sourceUrl}
                                alt={resourceSection.icon.altText}
                              />
                            )}
                            {resourceSection.title && (
                              <h3>{resourceSection.title}</h3>
                            )}
                          </div>
                          {resourceSection.text && (
                            <p>{resourceSection.text}</p>
                          )}
                        </div>
                        {
                          resourceSection.buttonMultipleExternalLinksPage && resourceSection.buttonMultipleExternalLinksPage.length > 0 ? (
                            <>
                              <button onClick={() => setIsOpen(true)}>{resourceSection.title}</button>
                              <ModalItem isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
                                <ul className='mobile-button-group'>
                                  {
                                    resourceSection.buttonMultipleExternalLinksPage.map(buttonLink => (
                                      buttonLink.link && (
                                        <li key={i}
                                          className={'mobile-button ' + resourceSection.navigatorButtonType.replace(/ /g, "-")}
                                        >
                                          <a href={buttonLink.link}>
                                            <span>{buttonLink.linkLabel}</span>
                                          </a>
                                        </li>
                                      )
                                    ))
                                  }
                                </ul>
                              </ModalItem>
                            </>
                          ) : (
                            <a
                              href={
                                resourceSection.linkType === "External Link"
                                  ? resourceSection.buttonUrl
                                  : resourceSection.buttonPage?.uri || ''
                              }
                              {...openExternalLink(resourceSection.linkType)}
                              className={resourceSection.linkType === "External Link" && resourceSection.buttonUrl.includes('tel:') && 'phone-only'}
                            >
                              {resourceSection.buttonText || formatMessage({ id: 'resourceListButtonText' })}
                            </a>
                          )
                        }
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

ResourceList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
}

ResourceList.defaultProps = {
  data: [],
}

export default ResourceList
